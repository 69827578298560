import { PlayerAudio } from "../../interfaces/player/player.interface";

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

export const defaultAudio: PlayerAudio = {
    id: 1,
    language: defaultLanguage,
    publisherTitle: 'Sample article',
    publisherDescription: 'Sample description',
    name: '',
    clientId: 0,
    clientName: '',
    topicId: 0,
    topicName: '',
    publisher: 'Odia',
    publicationDate: new Date().toISOString().split('T')[0],
    publisherWebsiteUrl: '',
    publisherLabel: 'Odia',
    duration: 100,
    audioUrl: '',
    order: 1,
    selected: true,
    autoPlay: false,
    visible: true,
    imageUrl: 'https://fastly.picsum.photos/id/11/2500/1667.jpg?hmac=xxjFJtAPgshYkysU_aqx2sZir-kIOjNR9vx0te7GycQ',
    author: 'John Doe',
    articleSections: []
}
