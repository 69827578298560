import { PlayerAudio } from "../interfaces/player/player.interface";

export const setAudioFilterVisibility = (language: string, topicId: number, audioQueue: PlayerAudio[]): PlayerAudio[] => {
    let newAudioQueue = [...audioQueue];

    try {
        let order = 0;
        for (let audio of newAudioQueue) {
            if (audio?.language.substring(0, 2) === language.substring(0, 2) && (
                audio?.topicId === topicId || topicId === 0
            )) {
                audio.order = order;
                order++;
                audio.visible = true;
            }
            else {
                audio.order = -1;
                audio.visible = false;
            }
        }

    } catch (error) {
        console.error(error);
    }

    return newAudioQueue;
}
