
// mui
import { Card, CardContent, CardMedia, Link, Stack, Typography } from "@mui/material"
import WatchIcon from '@mui/icons-material/Watch';

// handlers
import { formatDurationPunctuated } from "../../player/odiaPlayerHandlers";

// interfaces
import { PlayerAudio } from "../../../interfaces/player/player.interface";


interface Props {
    audio: PlayerAudio,
    visualStyle: AudioDescriptorVisualStyle
}

interface AudioDescriptorVisualStyle {
    showTitle: boolean,
    showDescription: boolean,
    showDate: boolean,
    showDuration: boolean,
    showPublisher: boolean,
    showTopic: boolean,
    showImage: boolean,
    truncateTitle: boolean,
    truncateDescription: boolean
}


export const LargeAudioCard = ({ audio, visualStyle }: Props) => {
    const {
        publisherTitle,
        imageUrl,
        publisher,
        publicationDate,
        publisherWebsiteUrl,
        duration
    } = audio;

    const {
        showDate,
        showDuration,
        showPublisher
    } = visualStyle;

    const cardHeight = '400px';

    return (
        <Card
            elevation={0}
            sx={{
                height: { cardHeight },
                position: "relative",
                borderRadius: 5,
                border: '3px solid rgba(0, 0, 0, 0.03)',
            }}
        >
            <CardMedia
                component="img"
                height={cardHeight}
                image={imageUrl}
            />
            {
                showPublisher &&
                <CardContent
                    sx={{
                        background: 'rgba(0,0,0,0.5)',
                        borderRadius: 3,
                        direction: "column",
                        height: '34px',
                        justifyContent: "center",
                        alignContent: 'center',
                        left: 15,
                        p: 0,
                        position: "absolute",
                        top: 15
                    }}
                >
                    <Typography
                        aria-label='playlistAudioPublisher'
                        color='white'
                        component={'div'}
                        id="publisher"
                        sx={{ textShadow: '0px 1px 9px rgba(0,0,0,0.74)' }}
                        variant="h6"
                    >
                        {
                            (publisherWebsiteUrl && publisherWebsiteUrl !== '') ?
                                <Link href={publisherWebsiteUrl}><b>{publisher}</b></Link> 
                                : <b>{publisher}</b>
                        }
                    </Typography>
                </CardContent>
            }
            {
                <Stack
                    direction='column'
                    sx={{
                        padding: 0,
                        position: "absolute",
                        width: '100%',
                        height: "120px",
                        color: "white",
                        left: 0,
                        top: 280,
                        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0) 100%)',
                        borderRadius: '1px'
                    }}
                >
                    <div id="large-title">
                        <Typography
                            aria-label='label title'
                            color='white'
                            variant="h5"
                            sx={{
                                paddingBottom: "20px"
                            }}
                        >
                            <b>{publisherTitle}</b>
                        </Typography>
                    </div>
                    <div id="dateInfo" style={{ paddingLeft: '20px' }}>
                        <Stack direction='row'>
                            {
                                (
                                    showDate
                                ) &&
                                <Typography
                                    aria-label='playlistAudioPublisherDate'
                                    color='white'
                                    variant="subtitle2"
                                    mr={6}
                                >
                                    <b>{publicationDate}</b>
                                </Typography>
                            }
                            {
                                showDuration &&
                                duration > 0 &&
                                <Stack direction='row'>
                                    <WatchIcon sx={{ height: 17, paddingTop: "2px" }} />
                                    <Typography
                                        aria-label='playlistAudioDuration'
                                        color='white'
                                        variant="caption"
                                    >
                                        <b>{formatDurationPunctuated(duration)}</b>
                                    </Typography>
                                </Stack>
                            }
                        </Stack>
                    </div>
                </Stack>
            }
        </Card >
    )
}