import { useContext } from "react";

// modules
import { FormattedMessage } from "react-intl";

// components
import { LangSelector } from "../langSelector/LangSelector";
import { SpeedRateSelector } from "./speedRateSelector/SpeedRateSelector";

// contexts
import { PlayerContext } from "../../contexts/PlayerContext";

// interfaces
import { PlayerData, PlayerProps } from '../../interfaces/player/player.interface';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    Forward10,
    Headset,
    PauseRounded,
    PlayArrowRounded,
    PlayCircle,
    Replay10,
    SkipNext,
    SkipPrevious,
} from '@mui/icons-material';

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

// actions
import {
    handleMute,
    handlePlayPause,
    handleSeekTo,
} from '../player/playerHandlers';

import { handleSkipTo } from "../playlist/playlistHandlers";


interface PlayButtonProps {
    playing: boolean,
    color: string,
    playButtonIconSize: string,
    playButtonIconCircle: boolean
}

const PlayButton = ({ playing, color, playButtonIconSize, playButtonIconCircle }: PlayButtonProps) => {
    return (
        <>
            {
                playing
                    ? (<PauseRounded sx={{ fontSize: playButtonIconSize }} htmlColor={color} />)
                    : (
                        playButtonIconCircle
                            ? <PlayCircle sx={{ fontSize: playButtonIconSize }} htmlColor={color} />
                            : <PlayArrowRounded sx={{ fontSize: playButtonIconSize }} htmlColor={color} />
                    )
            }
        </>
    )
}

export const PlayerButtons = () => {
    const theme = useTheme();
    const { palette } = theme;

    const playerContext = useContext(PlayerContext);
    const { style, data, props, type: playerType } = playerContext;

    const {
        playerRef,
        playerState,
        setPlayerState,
        position,
    } = props as PlayerProps;

    const { audioQueue } = data as PlayerData;
    const { visual, buttons } = style;

    const {
        buttonsSize,
        languageButtonDisplayRight,
        playButtonIconCircle,
        playButtonIconSize,
        primaryColor,
        showHeadsetIcon,
    } = visual;
    const {
        showPlayerForwardButton,
        showPlayerLanguageButton,
        showPlayerNextAudioButton,
        showPlayerPreviousAudioButton,
        showPlayerRewindButton,
        showPlayerSpeedButton,
        showMuteButton
    } = buttons;

    const getSize = (buttonsSize: string): string => {
        switch (buttonsSize) {
            case 'small':
                return '1.5rem';
            case 'medium':
                return '2rem';
            case 'large':
                return '4rem';
            default:
                return '1rem';
        }
    }

    const fontSize = getSize(buttonsSize)
    const buttonsColor = palette.mode !== 'dark' ? primaryColor : palette.text.secondary

    const tooltipPlacement = playerType.mode === 'player' ? 'right' : 'top';

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '55px',
                mb: (buttonsSize !== 'large' ? 0 : 2)
            }}
        >
            {
                showHeadsetIcon &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-listen-label' />}
                >
                    <Headset htmlColor="inherit" sx={{ opacity: 0.3, mr: 1 }} />
                </Tooltip>
            }
            {
                showMuteButton &&
                <Tooltip
                    placement="top"
                    title={<FormattedMessage id='app.player-volume-off-button' />}
                >
                    <IconButton
                        aria-label="volume off"
                        onClick={() => handleMute(playerState, setPlayerState)}
                    >
                        {
                            !playerState.muted &&
                            <VolumeUpIcon htmlColor={buttonsColor} />
                        }
                        {
                            playerState.muted &&
                            <VolumeOffIcon htmlColor={buttonsColor} />
                        }
                    </IconButton>
                </Tooltip>
            }

            {
                showPlayerLanguageButton && audioQueue.length > 1 && !languageButtonDisplayRight &&
                <LangSelector />
            }
            {
                showPlayerPreviousAudioButton &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-previous-button' />}
                >
                    <IconButton
                        id="previousButton"
                        aria-label="previous audio"
                        onClick={() => handleSkipTo(-1, playerContext)}
                    >
                        <SkipPrevious htmlColor={buttonsColor} sx={{ fontSize }} />
                    </IconButton>
                </Tooltip>
            }
            {
                showPlayerRewindButton &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-rewind10-button' />}
                >
                    <IconButton
                        aria-label="replay 10"
                        onClick={() => handleSeekTo(position, playerRef, -10)}
                    >
                        <Replay10 htmlColor={buttonsColor} sx={{ fontSize }} />
                    </IconButton>
                </Tooltip>
            }
            <Tooltip
                placement={tooltipPlacement}
                title={
                    playerState.playing
                        ? <FormattedMessage id='app.player-pause-button' />
                        : <FormattedMessage id='app.player-play-button' />
                }
            >
                <IconButton
                    aria-label={playerState.playing ? 'pause' : 'play'}
                    data-test={`player-${playerState.playing ? 'pause' : 'play'}-button`}
                    id={playerState.playing ? 'pauseButton' : 'playButton'}
                    onClick={() => handlePlayPause(setPlayerState)}
                >
                    <PlayButton
                        playing={playerState.playing}
                        color={palette.mode === 'dark' ? palette.secondary.light : buttonsColor}
                        playButtonIconSize={playButtonIconSize}
                        playButtonIconCircle={playButtonIconCircle}
                    />
                </IconButton>
            </Tooltip>
            {
                showPlayerForwardButton &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-forward10-button' />}
                >
                    <IconButton
                        aria-label="forward ten"
                        onClick={() => handleSeekTo(position, playerRef, 10)}
                    >
                        <Forward10 htmlColor={buttonsColor} sx={{ fontSize }} />
                    </IconButton>
                </Tooltip>
            }
            {
                showPlayerNextAudioButton &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-next-button' />}
                >
                    <IconButton
                        id="nextButton"
                        aria-label="next audio"
                        onClick={() => handleSkipTo(+1, playerContext)}
                    >
                        <SkipNext htmlColor={buttonsColor} sx={{ fontSize }} />
                    </IconButton>
                </Tooltip>
            }
            {
                showPlayerSpeedButton &&
                <SpeedRateSelector />
            }
            {
                showPlayerLanguageButton && audioQueue.length > 1 && languageButtonDisplayRight &&
                <LangSelector />
            }
        </Box>
    )
}
