import { useContext } from "react";

// modules
import { FormattedMessage } from "react-intl";

// components
import { Player } from "../Player";
import { PlayerButtons } from "../../playerButtons/PlayerButtons";
import { ListenLabel } from "../listenLabel/ListenLabel";

// contexts
import { AppContext } from "../../../contexts/AppContext";
import { PlayerContext } from "../../../contexts/PlayerContext";

// interfaces
import {
  PlayerData,
  PlayerProps,
} from "../../../interfaces/player/player.interface";

// mui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Slider, Stack, Typography } from "@mui/material";

// img
import logoOdia from "../../../img/logo-odia-original.png";
import logoOdiaLight from "../../../img/logo-odia-original-light.png";

const Widget = styled("div")({
  padding: 4,
  borderRadius: 16,
  width: "98vw",
  maxWidth: "100%",
  margin: "auto",
  position: "relative",
  zIndex: 1,
});

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

export const SimplePlayer = () => {
  const theme = useTheme();
  const { palette } = theme;

  const playerContext = useContext(PlayerContext);
  const { style, data, props } = playerContext;

  const { appLang } = useContext(AppContext);
  const { lang } = appLang;

  const {
    playerRef,
    playerState,
    position,
    setPosition,
    handleSliderChange,
    handleSliderChangeCommited,
    formatDuration,
  } = props as PlayerProps;

  const { currentAudio } = data as PlayerData;
  const { visual } = style;

  const {
    primaryColor,
    secondaryColor,
    backgroundColor,
    boxShadow,
    showOdiaLabel,
    showOdiaLabelIcon,
    showPlayerTitle,
    showPlayerDescription,
    showListenLabel,
    linkToWebsite,
  } = visual;

  const bgColor =
    palette.mode !== "dark" ? backgroundColor : palette.background.paper;

  return (
    <Box
      aria-label="simplePlayer"
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        m: 0,
        overflow: "hidden",
        borderRadius: 4,
        boxShadow,
        backgroundColor: bgColor,
        maxWidth: "100%",
      }}
    >
      <Player />
      <Widget>
        <Stack
          spacing={2}
          direction="row"
          sx={{ mb: 1, px: 1 }}
          alignItems="center"
        >
          <PlayerButtons />
          {showListenLabel && position === 0 && <ListenLabel />}

          <Stack
            spacing={1}
            direction="column"
            sx={{
              mb: 1,
              px: 1,
              width: "95vw",
              maxWidth: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {showPlayerTitle && (
                  <Typography
                    aria-label="label title"
                    color={primaryColor}
                    noWrap
                  >
                    <b>{currentAudio?.publisherTitle}</b>
                  </Typography>
                )}
                {showPlayerDescription && (
                  <Typography
                    aria-label="label description"
                    color={secondaryColor}
                    fontWeight={500}
                    variant="caption"
                  >
                    {currentAudio?.publisherDescription}
                  </Typography>
                )}
              </Box>
              {(showOdiaLabel || showOdiaLabelIcon) && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {showOdiaLabel && (
                    <Typography
                      aria-label="logo text"
                      variant="caption"
                      color="text.secondary"
                    >
                      <FormattedMessage id="app.player-poweredBy-label" />
                      &nbsp;
                    </Typography>
                  )}
                  {showOdiaLabelIcon && linkToWebsite ? (
                    <a
                      href={`https://www.odialab.com/${
                        lang.current === "fr-FR" ? "fr" : "en"
                      }/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        aria-label="logo icon"
                        src={palette.mode === "dark" ? logoOdiaLight : logoOdia}
                        alt="logo"
                        style={{ height: "15px" }}
                      />
                    </a>
                  ) : (
                    <img
                      aria-label="logo icon"
                      src={palette.mode === "dark" ? logoOdiaLight : logoOdia}
                      alt="logo"
                      style={{ height: "15px" }}
                    />
                  )}
                </Box>
              )}
            </Box>

            {/* reproduction bar */}
            <Stack
              spacing={1}
              direction="row"
              sx={{ mb: 0, px: 0 }}
              alignItems="center"
            >
              {position > 0 && (
                <TinyText aria-label="current-duration-label" sx={{ pr: 1 }}>
                  {formatDuration(position)}
                </TinyText>
              )}

              <Slider
                aria-label="simple-player-slider"
                size="small"
                value={position}
                min={0}
                max={playerState.duration}
                step={1}
                onChange={(e, value) =>
                  handleSliderChange(e, value, setPosition)
                }
                onChangeCommitted={(e, value) =>
                  handleSliderChangeCommited(e, value, setPosition, playerRef)
                }
                sx={{
                  color: primaryColor,
                  height: 4,
                  "& .MuiSlider-thumb": {
                    width: 8,
                    height: 8,
                    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                    "&:before": {
                      boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: `0px 0px 0px 8px ${
                        theme.palette.mode === "dark"
                          ? "rgb(255 255 255 / 16%)"
                          : "rgb(0 0 0 / 16%)"
                      }`,
                    },
                    "&.Mui-active": {
                      width: 20,
                      height: 20,
                    },
                  },
                  "& .MuiSlider-rail": {
                    opacity: 0.28,
                  },
                }}
              />

              <TinyText aria-label="total-duration-label" sx={{ pr: 1 }}>
                {formatDuration(playerState.duration)}
              </TinyText>
            </Stack>
          </Stack>
        </Stack>
      </Widget>
    </Box>
  );
};
