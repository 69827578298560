import { PlayerAudio } from "../interfaces/player/player.interface";
import { Topic } from "../interfaces/player/playerRequest.interface";


export const getTopicId = (topic: string, topicList: Topic[]): number => {
    const topicData = topicList.filter(t => t.code.toLowerCase() === topic.toLowerCase())[0]
    const id = topicData ? topicData.id : 0;
    return id;
}

export const getTopicDescription = (topicId: number, topicList: Topic[]): string => {
    const topicData = topicList.filter(t => t.id === topicId)[0]
    const description = topicData ? topicData.label : topicList[0].label;
    return description;
}

export const getTopicsFromAudioList = (audioQueue: PlayerAudio[]): number[] => {
    const topics: number[] = []

    for (const audio of audioQueue) {
        if (!topics.includes(audio.topicId)) {
            topics.push(audio.topicId);
        }
    }

    return topics//.sort()
}
