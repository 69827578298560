import { Box, Slider, styled, Typography, useTheme } from "@mui/material"
import { useContext } from "react";
import { PlayerContext } from "../../../contexts/PlayerContext";
import { PlayerProps } from "../../../interfaces/player/player.interface";

const TinyText = styled(Typography)({
    fontSize: '0.75rem',
    color: 'inherit',
    opacity: 0.38,
    fontWeight: 500,
    letterSpacing: 0.2,
});

export const PlayerSlider = () => {
    const theme = useTheme();
    const playerContext = useContext(PlayerContext);
    const { style, props } = playerContext;
    const {
        playerRef,
        playerState,
        position,
        setPosition,
        handleSliderChange,
        handleSliderChangeCommited,
        formatDuration
    } = props as PlayerProps;

    const { visual } = style;
    const { primaryColor } = visual;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: 1,
                height: '15px'
            }}
        >
            <TinyText
                aria-label='current-duration-label'
                m={'0px 12px 0px 3px'}
            >
                {formatDuration(position)}
            </TinyText>
            <Slider
                aria-label="simple-playlist-slider"
                size="small"
                value={position}
                min={0}
                max={playerState.duration}
                step={1}
                onChange={(e, value) => handleSliderChange(e, value, setPosition)}
                onChangeCommitted={(e, value) => handleSliderChangeCommited(e, value, setPosition, playerRef)}
                sx={{
                    color: primaryColor,
                    height: 4,
                    '& .MuiSlider-thumb': {
                        width: 8,
                        height: 8,
                        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                        '&:before': {
                            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                        },
                        '&:hover, &.Mui-focusVisible': {
                            boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                                ? 'rgb(255 255 255 / 16%)'
                                : 'rgb(0 0 0 / 16%)'
                                }`,
                        },
                        '&.Mui-active': {
                            width: 20,
                            height: 20,
                        },
                    },
                    '& .MuiSlider-rail': {
                        opacity: 0.28,
                    },
                }}
            />
            <TinyText
                aria-label='total-duration-label'
                m={'0px 3px 0px 12px'}
            >
                {formatDuration(playerState.duration)}
            </TinyText>
        </Box>
    )
}