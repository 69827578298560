import { useContext } from 'react';

// modules
import { FormattedMessage } from 'react-intl';

// contexts
import { PlayerContext } from '../../../contexts/PlayerContext';
import { AppContext } from '../../../contexts/AppContext';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Typography } from '@mui/material';
import { PlayerAudio } from '../../../interfaces/player/player.interface';

// handlers
import { formatDurationPunctuated } from '../../player/odiaPlayerHandlers';

// utils
import { formatDate } from '../../../utils/formatDate';

// interfaces
import { MobileOrientation } from '../../../interfaces/playlist/mobile.interface';


interface AudioDescriptorProps {
    audio: PlayerAudio,
    visualStyle: AudioDescriptorVisualStyle
    mobileViewOrientation?: MobileOrientation
}
interface AudioDescriptorVisualStyle {
    highlightSelectedAudio: boolean,
    imageSize: string,
    showDate: boolean,
    showDescription: boolean,
    showDuration: boolean,
    showImage: boolean,
    showPublisher: boolean,
    showTitle: boolean,
    showTopic: boolean,
    truncateDescription: boolean,
    truncateTitle: boolean,
}


export const AudioDescriptorSimpleItem = ({
    audio, visualStyle, mobileViewOrientation = 'portrait'
}: AudioDescriptorProps) => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const playerContext = useContext(PlayerContext);
    const { type, style } = playerContext;
    const { visual } = style;

    const {
        primaryColor,
        secondaryColor,
        displayAudioContent
    } = visual;

    const {
        publisherTitle,
        publisherWebsiteUrl,
        publisherLabel,
        imageUrl,
        publicationDate,
        topicId,
        duration,
        selected,
    } = audio;

    const {
        highlightSelectedAudio,
        imageSize,
        showDate,
        showDuration,
        showImage,
        showPublisher,
        showTitle,
        showTopic,
    } = visualStyle;

    return (
        <Stack
            direction='row'
            sx={
                mobileViewOrientation === 'portrait'
                    ? {
                        display: 'flex',
                        alignItems: 'top',
                        pb: '10px',
                        pl: 0,
                        pr: '10px'
                    }
                    : {
                        display: 'flex',
                        alignItems: 'top',
                        pt: 2,
                        pl: 0.2,
                        pr: '15px'
                    }
            }
        >
            {
                showImage &&
                imageUrl &&
                imageUrl !== '' &&
                <Box
                    display="flex"
                    alignItems={"top"}
                    justifyContent="center"
                    pr={mobileViewOrientation === 'portrait' ? 2 : 1}
                >
                    <img
                        id="article-image"
                        src={imageUrl}
                        alt={publisherTitle}
                        style={{
                            border:
                                selected && highlightSelectedAudio
                                    ? displayAudioContent ? `2px solid ${palette.secondary.light}`
                                        : `2px solid ${primaryColor}`
                                    : `2px solid transparent`,
                            borderRadius: 6,
                            height: imageSize,
                            objectFit: 'cover',
                            width: imageSize,
                        }}
                    />
                </Box>
            }
            <Stack
                direction='column'
            >
                {
                    showTitle &&
                    <Box
                        maxHeight={'120px'}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}
                    >
                        <Typography
                            aria-label='label title'
                            color={selected ? displayAudioContent ? palette.secondary.light : primaryColor : secondaryColor}
                            variant={type.design === "large" ? "caption" : "subtitle2"}
                            fontSize={"0.8rem"}
                        >
                            <b>{publisherTitle}</b>
                        </Typography>

                    </Box>

                }
                {
                    (
                        showPublisher ||
                        showDate ||
                        showTopic ||
                        showDuration
                    )
                    &&
                    <Stack direction='row'>
                        <div id="largePlayerDescription">
                            {
                                (
                                    showPublisher ||
                                    showDate
                                ) &&
                                <Typography
                                    aria-label='playlistAudioPublisherDate'
                                    color={secondaryColor}
                                    variant="caption"
                                    mr={2}
                                >
                                    {
                                        showPublisher && type.design !== "large" && mobileViewOrientation !== 'landscape' && type.design !== "portal" &&
                                        <>
                                            <FormattedMessage id='app.playlist-by-label' />&nbsp;
                                        </>
                                    }
                                    {
                                        showPublisher &&
                                        <>
                                            {
                                                (publisherWebsiteUrl && publisherWebsiteUrl !== '') ?
                                                    <Link href={publisherWebsiteUrl} color={secondaryColor}><>{publisherLabel}</></Link>
                                                    : <>{publisherLabel}&nbsp;</>
                                            }
                                        </>
                                    }
                                    {
                                        showDate &&
                                        <>&nbsp;
                                            {formatDate(publicationDate, currentContextLanguage)}
                                        </>
                                    }
                                </Typography>
                            }
                            {
                                !displayAudioContent &&
                                showTopic &&
                                topicId > -1 &&
                                <Typography
                                    aria-label='playlistAudioTopic'
                                    color={secondaryColor}
                                    variant="caption"
                                    mr={2}
                                >
                                    <b>
                                        <FormattedMessage id={`app.speech-topics-id-${topicId}`} />
                                    </b>
                                </Typography>
                            }
                            {
                                showDuration &&
                                duration > 0 &&
                                <Typography
                                    aria-label='playlistAudioDuration'
                                    color={secondaryColor}
                                    variant="caption"
                                >
                                    <b>{formatDurationPunctuated(duration)}</b>
                                </Typography>
                            }
                        </div>
                    </Stack>
                }
            </Stack>
        </Stack>
    )
}