// react imports
import { useContext, useState, useEffect, useRef } from 'react';

// components
import { SimplePlayer } from './simplePlayer/SimplePlayer';
import { CompactPlayer } from './compactPlayer/CompactPlayer';

// interfaces
import { OdiaPlayerProps } from '../../interfaces/player/odiaPlayer.interface';
import {
    Player,
    PlayerProps,
    PlayerAudio
} from '../../interfaces/player/player.interface';

// actions
import {
    formatDuration,
    handleSliderChange,
    handleSliderChangeCommited
} from './odiaPlayerHandlers';

// contexts
import { AppContext } from '../../contexts/AppContext';
import { PlayerContext } from '../../contexts/PlayerContext';

// types
import { defaultPlayerState } from '../../types/default/defaultPlayerState';


export const OdiaPlayer = (props: OdiaPlayerProps) => {
    const { playerType, playerStyle, audioQueue } = props;
    const { appLang } = useContext(AppContext);
    const { lang } = appLang;

    // get audio from current language
    let audioFilter: PlayerAudio[] = [];
    if (audioQueue.length > 1) {
        audioFilter = audioQueue.filter(audio => audio.language.substring(0, 2) === lang.current.substring(0, 2));
        if (audioFilter.length === 0) audioFilter.push(audioQueue[0])
    } else {
        audioFilter.push(audioQueue[0])
    }

    const currentAudio = audioFilter[0];

    const playerRef = useRef(null);

    const [playerState, setPlayerState] = useState({
        ...defaultPlayerState
    });
    const [playerData, setPlayerDataState] = useState({
        currentAudio,
        audioQueue
    });

    const [position, setPosition] = useState(0);
    const [showAudioTextContent, setShowAudioTextContent] = useState(false);

    const playerProps: PlayerProps = {
        playerRef,
        playerState,
        setPlayerState,
        setPlayerDataState,
        position,
        setPosition,
        handleSliderChange,
        handleSliderChangeCommited,
        formatDuration,
        showAudioTextContent,
        setShowAudioTextContent

    }

    const player: Player = {
        type: playerType,
        props: playerProps,
        data: playerData,
        style: playerStyle
    }


    useEffect(() => {
        setPlayerState((state) => ({
            ...state,
            url: currentAudio?.audioUrl,
            played: 0,
            loaded: 0,
            duration: 0,
            loadAgain: false,
            playing: player.type.autoplay,
            muted: player.type.autoplay
        }))
        setPlayerDataState((state) => ({
            ...state,
            currentAudio
        }))

        return () => {
            setPlayerState((state) => ({
                ...state,
                url: '',
                played: 0,
                loaded: 0,
                duration: 0,
                loadAgain: false,
                playing: player.type.autoplay,
                muted: player.type.autoplay
            }))
        }
    },
        // eslint-disable-next-line
        [currentAudio])

    useEffect(() => {
        setPosition(playerState.playedSeconds as number)
    }, [playerState.playedSeconds])


    return (
        <PlayerContext.Provider value={player}>
            {
                player.type.design === 'simple' && <SimplePlayer />
            }
            {
                player.type.design === 'compact' && <CompactPlayer />
            }
        </PlayerContext.Provider>
    )
}
