import { getTopicsFromAudioList } from "./topicUtils";

import { PlayerAudio } from "../interfaces/player/player.interface";


export const setLargePlaylistAudioOrder = (audioQueue: PlayerAudio[]): PlayerAudio[] => {
    const topics = getTopicsFromAudioList(audioQueue);

    let newAudioQueue: PlayerAudio[] = [];
    let audioOrder = 0;

    topics.forEach(topic => {
        const topicAudios = audioQueue?.filter(audio => audio.topicId === topic);
        for (let audio of topicAudios) {
            audio.order = audioOrder;
            newAudioQueue.push(audio);
            audioOrder++;
        }
    });

    return newAudioQueue;
}
