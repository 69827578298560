import { Player, PlayerProps } from "../../interfaces/player/player.interface";
import { Topic } from "../../interfaces/player/playerRequest.interface";

import { handleTopicSelection } from "../playlist/playlistHandlers";

const backendRoute: string = process.env.REACT_APP_BACKEND_ROUTE || '';
const audioGuideUrl = `${backendRoute}/audioGuide`

export const handleSpeechCommand = async (
    playerAction: string,
    language: string,
    audioGuideActivated: boolean,
    playerContext: Player,
    setOpenTopicModal: React.Dispatch<React.SetStateAction<boolean>>,
    topicSelected: string = '',
    topics: Topic[] = [],
    setAudioGuideNextSrc: React.Dispatch<React.SetStateAction<string>>,
    setPlayAudioGuide: React.Dispatch<React.SetStateAction<boolean>>
) => {
    switch (playerAction) {
        case 'wakeup':
            document.getElementById('landingPageClose')?.click();
            if (audioGuideActivated) {
                setAudioGuideNextSrc(`context=topicSelected&topic=${topicSelected}`);
                setPlayAudioGuide(true);
            }
            break;

        case 'play':
            document.getElementById('playButton')?.click();
            break;

        case 'pause':
            document.getElementById('pauseButton')?.click();
            break;

        case 'next':
            document.getElementById('nextButton')?.click();
            document.getElementById('playButton')?.click();
            break;

        case 'previous':
            document.getElementById('previousButton')?.click();
            document.getElementById('playButton')?.click();
            break;

        case 'changeTopic':
            document.getElementById('pauseButton')?.click();
            if (audioGuideActivated) {
                setAudioGuideNextSrc(`context=topicSelection`);
                setPlayAudioGuide(true);
            }
            document.getElementById('topicMenuOpen')?.click();
            break;

        case 'topicSelect':
            const { props } = playerContext;
            const { playerState } = props as PlayerProps;

            const topic: Topic = topics.filter(t => t.code === topicSelected)[0];
            const { id: topicSelectedId } = topic;

            if (audioGuideActivated) {
                setAudioGuideNextSrc(`context=topicSelected&topic=${topicSelected}`);
                setPlayAudioGuide(true);
            }
            if (playerState.topic !== topicSelected) {
                // select topic and play first audio
                const playFirstAudio = true;
                handleTopicSelection(
                    playerContext,
                    language,
                    setOpenTopicModal,
                    topics,
                    topicSelectedId,
                    playFirstAudio
                );
                if (audioGuideActivated) {
                    document.getElementById('pauseButton')?.click();
                }
            } else {
                document.getElementById('playButton')?.click();
            }
            break;

        default:
            document.getElementById('pauseButton')?.click();
            document.getElementById(`topic_${topicSelected}`)?.click();
            document.getElementById('simple-popover')?.click();
            document.getElementById(`topic-${topicSelected}-card`)?.click();
            break;
    }
}

export const getActivateASRAudio = (currentLanguage: string): HTMLAudioElement => {
    const audio: HTMLAudioElement = new Audio(audioGuideUrl + '?lang=' + currentLanguage + '&context=introduction');
    return audio;
}
