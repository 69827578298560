import React, { useCallback, useContext, useEffect } from "react";

// external packages
import ReactPlayer from 'react-player';

// context
import { PlayerContext } from "../../contexts/PlayerContext";
import { registerConsumption } from "../../services/registerConsumption";

// player handlers
import {
    handleReady,
    handlePlay,
    handlePause,
    handleBuffering,
    handleBufferEnd,
    handleEnded,
    handleProgress,
    handleDuration
} from './playerHandlers';

// interfaces
import { PlayerAudio, PlayerProps } from "../../interfaces/player/player.interface";


export const Player = React.memo(() => {
    const playerContext = useContext(PlayerContext);
    const { props } = playerContext;
    const { playerRef, playerState, setPlayerState } = props as PlayerProps;
    const {
        controls,
        duration,
        light,
        loop,
        muted,
        playbackRate,
        playedSecondList,
        playedSeconds,
        playing,
        url,
        volume,
    } = playerState;

    const saveConsumption = useCallback(() => {
        if (playerContext.data) {
            let {
                name: audioName,
                clientName,
                publisher,
                autoPlay,
                topicName
            } = playerContext?.data?.currentAudio as PlayerAudio;

            if (!topicName)
                topicName = "";
            if (!publisher)
                publisher = "";

            registerConsumption(
                duration,
                playedSecondList,
                audioName,
                clientName,
                publisher,
                topicName,
                autoPlay
            );
        }
    }, [playerContext.data, playedSecondList, duration])


    // effect for consumption, it stores the played seconds
    useEffect(() => {
        const playedSecond = Math.round(playedSeconds);
        if (!playedSecondList.includes(playedSecond)) {
            playedSecondList.push(playedSecond) // save the played second
        }
    }, [playedSeconds, playedSecondList]);


    // Add effect to register consumption on window close
    useEffect(() => {
        window.addEventListener('beforeunload', saveConsumption);
        return () => {
            window.removeEventListener('beforeunload', saveConsumption);
        }
    }, [saveConsumption])


    return (
        <ReactPlayer
            ref={playerRef}
            data-test="player-component"
            width={'0'}
            height={0}
            style={{ display: 'none' }}
            url={url}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={() => handleReady(window)}
            onPlay={() => handlePlay(setPlayerState)}
            onPause={() => handlePause(setPlayerState)}
            onBuffer={() => handleBuffering(setPlayerState)}
            onBufferEnd={() => handleBufferEnd(setPlayerState)}
            onEnded={() => handleEnded(setPlayerState)}
            onError={e => console.error('onError', e)}
            onProgress={state => handleProgress(playerState, setPlayerState, state)}
            onDuration={duration => handleDuration(setPlayerState, duration)}
            progressInterval={200}
        />
    )
})
